import { addOriginalEventListener } from "./_events";
import { onInView } from "./_onInView";

const getParameter = (delayMS: number): gsap.TweenVars => ({
  delay: delayMS / 1000,
  duration: 0.5
});

type InViewFunc = (e: HTMLElement, delayMS: number) => void;

const slideUp: InViewFunc = (e, delayMS) => {
  gsap.fromTo(
    e,
    { opacity: 0, y: 50 },
    { opacity: 1, y: 0, ...getParameter(delayMS) }
  );
};

const zoomUp: InViewFunc = (e, delayMS) => {
  gsap.fromTo(
    e,
    { opacity: 0, scale: 0.3 },
    {
      opacity: 1,
      scale: 1,
      ease: Back.easeOut,
      ...getParameter(delayMS)
    }
  );
};

const fadeIn: InViewFunc = (e, delayMS) => {
  gsap.fromTo(e, { opacity: 0 }, { opacity: 1, ...getParameter(delayMS) });
};

const getFunc = (type: string | null): InViewFunc => {
  switch (type) {
    case "zoomUp":
      return zoomUp;

    case "fadeIn":
      return fadeIn;

    default:
      return slideUp;
  }
};

export const setupInView = (
  selector = "[data-js-in-view]",
  attribute = "data-js-in-view",
  groupSelector = "[data-js-in-view-group]"
): void => {
  addOriginalEventListener("ready", () => {
    const setUpped = new Set<HTMLElement>();

    const setup = (e: HTMLElement, trigger: HTMLElement, delayMS = 0): void => {
      if (setUpped.has(e)) return;

      // eslint-disable-next-line no-param-reassign
      e.style.opacity = "0";

      const func = getFunc(e.getAttribute(attribute));

      addOriginalEventListener("shown", () => {
        onInView(trigger, e, () => {
          func(e, delayMS);
        });
      });

      setUpped.add(e);
    };

    document.querySelectorAll<HTMLElement>(groupSelector).forEach((group) => {
      group.querySelectorAll<HTMLElement>(selector).forEach((e, i) => {
        setup(e, group, i * 100);
      });
    });

    document.querySelectorAll<HTMLElement>(selector).forEach((e) => {
      setup(e, e);
    });
  });
};
