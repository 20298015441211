/**
 * アニメーション処理を登録します。
 * @param handler - 処理
 */
export const raf = (handler: () => void): void => {
  window.requestAnimationFrame(handler);
};

/**
 * アニメーション処理を毎フレーム実行するよう登録します。
 * @param handler - 処理
 * @param skip - スキップするフレーム数
 */
export const rafLoop = (handler: () => void, skip: number): void => {
  let counter = 0;

  const loop = (): void => {
    raf(loop);

    if (counter === 0) {
      handler();
    }
    counter += 1;

    if (counter >= skip) counter = 0;
  };

  raf(loop);
};
