import { addOriginalEventListener } from "./_events";

/**
 * c-box のフェードイン処理
 *
 * @param selector - 対象の要素のセレクタ
 */
export const setupCBox = (selector = "[data-js-c-box]"): void => {
  addOriginalEventListener("shown", () => {
    document
      .querySelectorAll<HTMLElement>(selector)
      .forEach((x) => x.classList.add("is-active"));
  });
};
