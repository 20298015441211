/** Type: SVGパスの座標 */
type SvgPathCoord = { x: number; y: number };

/** Type: SVGパスの制御点 M */
type SvgPathPointM = {
  type: "M";
  points: [SvgPathCoord];
};

/** Type: SVGパスの制御点 C */
type SvgPathPointC = {
  type: "C";
  points: [SvgPathCoord, SvgPathCoord, SvgPathCoord];
};

/** Type: SVGパスの制御点 */
type SvgPathPoint = SvgPathPointM | SvgPathPointC;

/** Type: SVGパス */
export type SvgPath = SvgPathPoint[];

/**
 * 点を回転
 * @param coord - 点
 * @param cos - Cos
 * @param sin - Sin
 * @param centerX - 中心のX座標
 * @param centerY - 中心のY座標
 * @returns 点
 */
const rotate = (
  { x, y }: SvgPathCoord,
  cos: number,
  sin: number,
  centerX: number,
  centerY: number
): SvgPathCoord => {
  const dx = x - centerX;
  const dy = y - centerY;

  return {
    x: dx * cos - dy * sin + centerX,
    y: dx * sin + dy * cos + centerY
  };
};
/**
 * 点の拡大
 * @param coord - 点
 * @param sx - X方向の拡大率
 * @param sy - Y方向の拡大率
 * @param centerX - 中心のX座標
 * @param centerY - 中心のY座標
 * @returns 点
 */
const scale = (
  { x, y }: SvgPathCoord,
  sx: number,
  sy: number,
  centerX: number,
  centerY: number
): SvgPathCoord => {
  const dx = x - centerX;
  const dy = y - centerY;

  return {
    x: dx * sx + centerX,
    y: dy * sy + centerY
  };
};

/**
 * SVGパスを解析します。
 * @param svgPathString - SVGパスの文字列
 * @returns SVGパス
 */
export const parseSvgPath = (svgPathString: string): SvgPath => {
  const items = svgPathString.trim().split(/\s+/);

  const result: SvgPath = [];

  while (items.length > 0) {
    const type = items.shift();

    switch (type) {
      case "M":
        result.push({
          type,
          points: [{ x: +(items.shift() ?? "0"), y: +(items.shift() ?? "0") }]
        });
        break;

      case "C":
        result.push({
          type,
          points: [
            { x: +(items.shift() ?? "0"), y: +(items.shift() ?? "0") },
            { x: +(items.shift() ?? "0"), y: +(items.shift() ?? "0") },
            { x: +(items.shift() ?? "0"), y: +(items.shift() ?? "0") }
          ]
        });
        break;

      case "Z":
        break;

      default:
        throw new Error(`type "${type}" not supported`);
    }
  }

  return result;
};

/**
 * 平行移動させたSVGパスを作成します。
 * @param svgPath - SVGパス
 * @param dx - X方向の移動距離
 * @param dy - Y方向の移動距離
 * @returns SVGパス
 */
export const translateSvgPath = (
  svgPath: SvgPath,
  dx: number,
  dy: number
): SvgPath => {
  if (dx === 0 && dy === 0) return [...svgPath];

  const result: SvgPath = [];
  const { length } = svgPath;

  for (let i = 0; i < length; i += 1) {
    const point = svgPath[i];

    switch (point.type) {
      case "M":
        result.push({
          type: point.type,
          points: [{ x: point.points[0].x + dx, y: point.points[0].y + dy }]
        });
        break;

      case "C":
        result.push({
          type: point.type,
          points: [
            { x: point.points[0].x + dx, y: point.points[0].y + dy },
            { x: point.points[1].x + dx, y: point.points[1].y + dy },
            { x: point.points[2].x + dx, y: point.points[2].y + dy }
          ]
        });
        break;

      default:
    }
  }

  return result;
};

/**
 * 点を中心に回転させたSVGパスを作成します。
 * @param svgPath - SVGパス
 * @param degree - 回転角度
 * @param centerX - 点のX座標
 * @param centerY - 点のY座標
 * @returns SVGパス
 */
export const rotateSvgPath = (
  svgPath: SvgPath,
  degree: number,
  centerX: number,
  centerY: number
): SvgPath => {
  if (degree === 0) return [...svgPath];

  const rad = (Math.PI / 180) * degree;
  const cos = Math.cos(rad);
  const sin = Math.sin(rad);

  const result: SvgPathPoint[] = [];
  const { length } = svgPath;

  for (let i = 0; i < length; i += 1) {
    const point = svgPath[i];

    switch (point.type) {
      case "M":
        result.push({
          type: point.type,
          points: [rotate(point.points[0], cos, sin, centerX, centerY)]
        });
        break;

      case "C":
        result.push({
          type: point.type,
          points: [
            rotate(point.points[0], cos, sin, centerX, centerY),
            rotate(point.points[1], cos, sin, centerX, centerY),
            rotate(point.points[2], cos, sin, centerX, centerY)
          ]
        });
        break;

      default:
    }
  }

  return result;
};

/**
 * 点を中心に拡大・縮小させたSVGパスを作成します。
 * @param svgPath - SVGパス
 * @param sx - X方向の拡大率
 * @param sy - Y方向の拡大率
 * @param centerX - 点のX座標
 * @param centerY - 点のY座標
 * @returns SVGパス
 */
export const scaleSvgPath = (
  svgPath: SvgPath,
  sx: number,
  sy: number,
  centerX: number,
  centerY: number
): SvgPath => {
  if (sx === 0 && sy === 0) return [...svgPath];

  const newPoints: SvgPathPoint[] = [];
  const { length } = svgPath;

  for (let i = 0; i < length; i += 1) {
    const point = svgPath[i];

    switch (point.type) {
      case "M":
        newPoints.push({
          type: point.type,
          points: [scale(point.points[0], sx, sy, centerX, centerY)]
        });
        break;

      case "C":
        newPoints.push({
          type: point.type,
          points: [
            scale(point.points[0], sx, sy, centerX, centerY),
            scale(point.points[1], sx, sy, centerX, centerY),
            scale(point.points[2], sx, sy, centerX, centerY)
          ]
        });
        break;

      default:
    }
  }

  return newPoints;
};

/**
 * SVGパスを文字列化します。
 * @param svgPath - SVGパス
 */
export const buildSvgPath = (svgPath: SvgPath): string => {
  const d: string[] = [];
  const { length } = svgPath;

  for (let i = 0; i < length; i += 1) {
    const point = svgPath[i];

    switch (point.type) {
      case "M":
        d.push(
          point.type,
          point.points[0].x.toFixed(4),
          point.points[0].y.toFixed(4)
        );
        break;

      case "C":
        d.push(
          point.type,
          point.points[0].x.toFixed(4),
          point.points[0].y.toFixed(4),
          point.points[1].x.toFixed(4),
          point.points[1].y.toFixed(4),
          point.points[2].x.toFixed(4),
          point.points[2].y.toFixed(4)
        );
        break;

      default:
    }
  }

  return `${d.join(" ")} Z`;
};
