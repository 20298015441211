import { getMobileQuery } from "./function/_mobileQuery";
import { getMouseQuery } from "./function/_mouseQuery";
import { setupCBox } from "./modules/_c-box";
import { setupCover } from "./modules/_cover";
import { setupCssValue } from "./modules/_cssValue";
import { isDialogShown, setupDialog } from "./modules/_dialog";
import { addOriginalEventListener } from "./modules/_events";
import { setupInView } from "./modules/_inView";
import { setupLetterAnimation } from "./modules/_letterAnimation";
import { setupMenu } from "./modules/_menu";
import { onInView } from "./modules/_onInView";
import { setupStartup } from "./modules/_startup";

setupCssValue();
setupStartup();
setupCover();
setupInView();
setupLetterAnimation();
setupCBox();
window.addEventListener("DOMContentLoaded", () => {
  setupMenu();
  setupDialog();
});

const siteImpl = {
  addOriginalEventListener,
  onInView,
  isDialogShown,
  getMobileQuery,
  getMouseQuery
};

declare global {
  const site: typeof siteImpl;
  interface Window {
    site: typeof siteImpl;
  }
}

window.site = siteImpl;
