/** オリジナルイベントの定義 */
const ORIGINAL_EVENTS = {
  /** オリジナルイベント: 準備完了（DOM & Webフォント読み込み完了時） */
  ready: "ready",

  /** オリジナルイベント: 初期表示（カバーが外れた時） */
  shown: "shown"
} as const;

type OriginalEventType = typeof ORIGINAL_EVENTS[keyof typeof ORIGINAL_EVENTS];

type OriginalEventListener = () => void;

const listeners = new Map<OriginalEventType, OriginalEventListener[]>();

/**
 * 独自イベントのリスナーを設定
 *
 * @param type
 * @param listener
 */
export const addOriginalEventListener = (
  type: OriginalEventType,
  listener: OriginalEventListener
): void => {
  const entry = listeners.get(type) ?? [];

  entry.push(listener);
  listeners.set(type, entry);
};

/**
 * 独自イベントをトリガー
 * @param type
 */
export const triggerOriginalEvent = (type: OriginalEventType): void => {
  (listeners.get(type) ?? []).forEach((listener) => listener());
};
