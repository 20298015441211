import { getTarget } from "../function/_getTarget";

let busy = false;
let current: HTMLElement | null = null;

const showDialog = (dialog: HTMLElement): void => {
  if (busy) return;

  busy = true;

  current = dialog;
  dialog.setAttribute("open", "open");

  const timeline = gsap.timeline();

  timeline.add(
    gsap.fromTo(
      dialog.querySelector("[data-js-dialog-overlay]"),
      { opacity: 0 },
      { opacity: 1, duration: 0.3 }
    )
  );
  timeline.add(
    gsap.fromTo(
      dialog.querySelector("[data-js-dialog-content]"),
      { opacity: 0, scale: 0.9 },
      { opacity: 1, scale: 1, duration: 0.3, ease: Back.easeOut }
    ),
    "<"
  );
  timeline.add(() => {
    busy = false;
  });
};

const hideDialog = (): void => {
  if (busy || !current) return;

  busy = true;

  const timeline = gsap.timeline();

  timeline.add(
    gsap.fromTo(
      current.querySelector("[data-js-dialog-overlay]"),
      { opacity: 1 },
      { opacity: 0, duration: 0.3 }
    )
  );
  timeline.add(
    gsap.fromTo(
      current.querySelector("[data-js-dialog-content]"),
      { opacity: 1, scale: 1 },
      { opacity: 0, scale: 0.8, duration: 0.3, ease: Quad.easeOut }
    ),
    "<"
  );
  timeline.add(() => {
    if (current) current.removeAttribute("open");
    current = null;
    busy = false;
  });
};

export const isDialogShown = (): boolean => current !== null;

export const setupDialog = (
  triggerSelector = "[data-js-dialog-trigger]"
): void => {
  document.querySelectorAll(triggerSelector).forEach((trigger) => {
    const dialog = getTarget(trigger);

    if (!dialog) return;

    dialog.querySelectorAll("[data-js-dialog-overlay]").forEach((overlay) => {
      overlay.addEventListener("click", hideDialog);
    });
    dialog.querySelectorAll("[data-js-dialog-close]").forEach((overlay) => {
      overlay.addEventListener("click", hideDialog);
    });

    trigger.addEventListener("click", (ev) => {
      showDialog(dialog);

      ev.preventDefault();
      ev.stopPropagation();
    });
  });
};
