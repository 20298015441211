import { triggerOriginalEvent } from "./_events";

/** ロード状態 */
const loadingStatus = {
  font: false,
  dom: false
};

/**
 * 任意のローディングの完了を記録し、すべて終了したら開始リスナーを呼びます。
 *
 * @param key - ロード状態のキー
 */
const done = (key: keyof typeof loadingStatus): void => {
  loadingStatus[key] = true;

  for (const status of Object.values(loadingStatus)) {
    if (!status) return;
  }

  // 全ローディング終了
  triggerOriginalEvent("ready");
};

/** DOMローディングの検知関数 */
const detectDOMLoading = (): void => {
  window.addEventListener("load", () => {
    done("dom");
  });
};

/** フォントローディングの検知関数 */
const detectFontLoading = (): void => {
  const html = document.documentElement;

  const checkFontLoad = (): boolean =>
    html.classList.contains("wf-active") ||
    html.classList.contains("wf-inactive");

  if (checkFontLoad()) {
    done("font");

    return;
  }

  // MutationObserver で検知する
  const observer = new MutationObserver(() => {
    if (checkFontLoad()) {
      done("font");

      observer.disconnect();
    }
  });

  observer.observe(html, {
    attributes: true
  });
};

/** スタートアップ処理を設定 */
export const setupStartup = (): void => {
  detectDOMLoading();
  detectFontLoading();
};
