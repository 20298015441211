/** 動的な CSS 変数を設定します。 */
export const setupCssValue = (): void => {
  const html = document.documentElement;

  const update = (): void => {
    /** スクロールバーを除いた幅を --width に設定する */
    html.style.setProperty("--width", `${html.offsetWidth}`);
    html.style.setProperty("--height", `${html.clientHeight}`);
  };

  document.addEventListener("DOMContentLoaded", update);
  window.addEventListener("load", update);
  window.addEventListener("resize", update);
};
