import { RotatePath } from "../class/_RotatePath";
import { parseSvgPath } from "../class/_SvgPath";

const MASK = parseSvgPath(
  "M 111.36 7.11 C 189.86 6.15 229.97 53.8 242.73 115.69 C 260.94 199.32 166.18 244.1 102.24 242.1 C -24.29 231.44 -32.46 12.12 111.36 7.11 Z"
);

const menuItems: RotatePath[] = [];

let menuShown = false;

/** メニューを非表示にする */
export const hideMenu = (): void => {
  document.documentElement.classList.add("is-menu-hidden");
  document.documentElement.classList.remove("is-menu-shown");
  menuShown = false;

  for (const menuItem of menuItems) menuItem.pause();
};

/** メニューを表示する */
const showMenu = (): void => {
  document.documentElement.classList.remove("is-menu-hidden");
  document.documentElement.classList.add("is-menu-shown");
  menuShown = true;

  for (const menuItem of menuItems) menuItem.resume();
};

/** メニューの表示を切り替える */
const toggleMenu = (): void => {
  if (menuShown) {
    hideMenu();
  } else {
    showMenu();
  }
};

/**
 * メニューの初期化
 */
export const setupMenu = (): void => {
  document
    .querySelector("[data-js-menu-button]")
    ?.addEventListener("click", toggleMenu);

  document
    .querySelectorAll<HTMLElement>("[data-js-menu-item]")
    .forEach((e, i) => {
      menuItems.push(
        RotatePath.start({
          element: e,
          pathElement: e.querySelector<HTMLElement>("[data-js-menu-mask]"),
          path: MASK,
          width: 250,
          height: 250,
          degree: i * 90,
          minSpeed: 0.5,
          maxSpeed: 1.5
        }).pause()
      );
    });
};
