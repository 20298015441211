/**
 * 対象からリンク先の要素を推定します。
 *
 * @param x - 対象
 */
export const getTarget = (x: unknown): HTMLElement | undefined => {
  if (x === null || x === undefined) return undefined;

  if (typeof x === "object") {
    if (x instanceof HTMLAnchorElement) return getTarget(x.hash);

    if (x instanceof HTMLElement) return getTarget(x.getAttribute("href"));
  }

  if (typeof x !== "string") return undefined;

  const matches = /^#(.+)$/.exec(x);

  if (!matches) return undefined;

  const e = document.getElementById(matches[1]);

  if (e) return e;

  if (/^top$/i.test(matches[1])) return document.documentElement;

  return undefined;
};
