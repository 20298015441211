import { addOriginalEventListener } from "./_events";
import { onInView } from "./_onInView";

/** 文字のSPAN要素テンプレート */
const SPAN = document.createElement("span");

SPAN.className = "u-letter-animation";
SPAN.style.display = "inline-block";
SPAN.style.opacity = "0";

/**
 * 要素内の文字を分割してSPAN要素の配列にする
 * @param e - 対象の要素
 */
const getLetters = (e: HTMLElement): HTMLElement[] => {
  const result: HTMLElement[] = [];

  const childNodes = Array.from(e.childNodes);
  const newChildNodes: HTMLElement[] = [];

  childNodes.forEach((child) => {
    if (child instanceof HTMLElement) {
      // 子要素の分を追加
      result.push(...getLetters(child));
      newChildNodes.push(child);
    } else if (child instanceof Text) {
      // テキストを1文字ずつSPANにする
      (child.nodeValue ?? "")
        .trim()
        .split("")
        .forEach((x) => {
          const span = SPAN.cloneNode(false) as HTMLElement;

          span.textContent = x.length === 0 ? " " : x;
          result.push(span);
          newChildNodes.push(span);
        });
    }
  });

  // eslint-disable-next-line no-param-reassign
  e.textContent = "";
  newChildNodes.forEach((child) => e.appendChild(child));

  return result;
};

/**
 * アニメーション作成
 * @param e - 対象の要素
 * @param letters - SPANの配列
 */
const createAnimation = (e: HTMLElement, letters: HTMLElement[]): void => {
  const timeline = gsap.timeline().pause();

  onInView(e, e, () => {
    timeline.resume();
  });

  letters.forEach((span, i) => {
    timeline.add(
      gsap.fromTo(
        span,
        {
          y: "-0.25em",
          x: "0.125em",
          scale: 1.1,
          filter: "blur(10px)",
          opacity: 0
        },
        {
          y: 0,
          x: 0,
          scale: 1,
          filter: "blur(0px)",
          opacity: 1,
          duration: 0.2
        }
      ),
      i * 0.02
    );
  });
};

/**
 * 文字アニメーションの初期化
 * @param selector - 対象の要素のセレクタ
 */
export const setupLetterAnimation = (
  selector = "[data-js-letter-animation]"
): void => {
  document.querySelectorAll<HTMLElement>(selector).forEach((e) => {
    const letters = getLetters(e);

    letters[letters.length - 1].classList.add("is-last");

    addOriginalEventListener("shown", () => {
      createAnimation(e, letters);
    });
  });
};
