import { rafLoop } from "../function/_raf";

import {
  buildSvgPath,
  rotateSvgPath,
  scaleSvgPath,
  SvgPath,
  translateSvgPath
} from "./_SvgPath";

/** 回転パスのパラメータ */
type RotatePathParameter = {
  /** 対象の要素 */
  element: HTMLElement | null | undefined;
  /** パスを設定する要素 */
  pathElement: HTMLElement | null | undefined;
  /** パス */
  path: SvgPath;
  /** 幅 */
  width: number;
  /** 高さ */
  height: number;
  /** 開始角度 */
  degree: number;
  /** 最高速度 */
  maxSpeed: number;
  /** 最低速度 */
  minSpeed: number;
};

/** 回転するパス */
export class RotatePath implements EventListenerObject {
  /** パラメータ */
  #parameter: RotatePathParameter;
  /** 角度 */
  #degree: number;
  /** 速度 */
  #speed: number;
  /** 加速度 */
  #accel: number;
  /** 停止状態 */
  #paused: boolean;
  /** 拡大率 */
  #scale: number;
  /** X位置 */
  #x: number;
  /** Y位置 */
  #y: number;

  /**
   * アニメーションの開始
   * @param parameter - パラメータ
   */
  static start(parameter: RotatePathParameter): RotatePath {
    const instance = new RotatePath(parameter);

    instance.update();
    rafLoop(() => instance.update(), 5);

    return instance;
  }

  /**
   * コンストラクタ
   * @param parameter - パラメータ
   */
  constructor(parameter: RotatePathParameter) {
    const { element, pathElement } = parameter;

    this.#parameter = parameter;
    this.#parameter.element = undefined;
    this.#parameter.pathElement = undefined;
    this.#degree = parameter.degree;
    this.#speed = parameter.minSpeed;
    this.#accel = 0;
    this.#paused = false;
    this.#scale = 1;
    this.#x = 0;
    this.#y = 0;

    this.attach(element, pathElement);
  }

  pause(): RotatePath {
    this.#paused = true;

    return this;
  }

  resume(): RotatePath {
    this.#paused = false;

    return this;
  }

  handleEvent(ev: Event): void {
    switch (ev.type) {
      case "mouseenter":
        if (site.getMouseQuery().matches) {
          this.#accel = 0.1;
        }
        break;

      case "mouseleave":
        if (site.getMouseQuery().matches) {
          this.#accel = -0.1;
        }
        break;

      default:
    }
  }

  attach(
    element: HTMLElement | string | null | undefined,
    pathElement: HTMLElement | string | null | undefined
  ): void {
    this.detach();

    if (typeof element === "string") {
      this.attach(document.querySelector<HTMLElement>(element), pathElement);

      return;
    }

    if (typeof pathElement === "string") {
      this.attach(element, document.querySelector<HTMLElement>(pathElement));

      return;
    }

    this.#parameter.element = element;
    this.#parameter.pathElement = pathElement;

    element?.addEventListener("mouseenter", this);
    element?.addEventListener("mouseleave", this);
  }

  detach(): void {
    this.#parameter.element?.removeEventListener("mouseenter", this);
    this.#parameter.element?.removeEventListener("mouseleave", this);

    this.#parameter.element = undefined;
    this.#parameter.pathElement = undefined;
  }

  setScale(scale: number): void {
    this.#scale = scale;
  }

  setPosition(x: number, y: number): void {
    this.#x = x;
    this.#y = y;
  }

  /** 更新 */
  update(): void {
    if (this.#paused) return;

    // 速度を挙げる
    this.#speed += this.#accel;

    // 速度を最小～最大におさまるように調整
    if (this.#speed > this.#parameter.maxSpeed) {
      this.#speed = this.#parameter.maxSpeed;
      this.#accel = 0;
    } else if (this.#speed < this.#parameter.minSpeed) {
      this.#speed = this.#parameter.minSpeed;
      this.#accel = 0;
    }

    // 角度を更新
    this.#degree += this.#speed;

    // 回転 → 拡大 → 移動の順で処理
    let { path } = this.#parameter;
    path = rotateSvgPath(
      path,
      this.#degree,
      this.#parameter.width / 2,
      this.#parameter.height / 2
    );
    path = scaleSvgPath(
      path,
      this.#scale,
      this.#scale,
      this.#parameter.width / 2,
      this.#parameter.height / 2
    );
    path = translateSvgPath(path, this.#x, this.#y);

    // パスを反映
    if (this.#parameter.pathElement) {
      this.#parameter.pathElement.setAttribute("d", buildSvgPath(path));
    }
  }
}
