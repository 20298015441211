import { addOriginalEventListener, triggerOriginalEvent } from "./_events";

/**
 * カバー処理
 * @param selector - 対象の要素のセレクタ
 */
export const setupCover = (selector = "[data-js-cover]"): void => {
  addOriginalEventListener("ready", () => {
    gsap
      .to(selector, {
        opacity: 0,
        display: "none",
        duration: 0.5,
        ease: Expo.easeIn
      })
      .then(() => {
        triggerOriginalEvent("shown");
      });
  });
};
