type InViewCallback = (e: HTMLElement) => void;

export const onInView = (
  trigger: HTMLElement | string,
  element: HTMLElement | string,
  callback: InViewCallback
): void => {
  if (typeof trigger === "string") {
    document
      .querySelectorAll<HTMLElement>(trigger)
      .forEach((e) => onInView(e, element, callback));

    return;
  }

  if (typeof element === "string") {
    document
      .querySelectorAll<HTMLElement>(element)
      .forEach((e) => onInView(trigger, e, callback));

    return;
  }

  let installed = false;
  let shown = false;

  const show = (): void => {
    if (shown) return;
    shown = true;
    callback(element);
  };

  ScrollTrigger.create({
    trigger,
    start: "top 85%",
    end: "bottom 15%",
    onEnter() {
      if (installed) {
        show();
      }
    },
    onEnterBack() {
      if (installed) {
        show();
      }
    },
    onToggle() {
      installed = true;
    }
  });
};
